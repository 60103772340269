import axios from 'axios';
export const SOLD_OUT_LIMIT = 0;
export function isFullSizeImageProduct(product) {
    return product.productImageType === 'fullSize';
}
export function isTransparentImageProduct(product) {
    return product.productImageType === 'transparent';
}
export function filterProducts(products, isSubscriptionMode, activeCategory, activeSubCategory, filterOnSuppliers = false) {
    let productsToShow = [...products];
    if (isSubscriptionMode) {
        productsToShow = productsToShow.filter((product) => product.subscriptionProduct);
    }
    if (activeCategory) {
        if (filterOnSuppliers) {
            productsToShow = productsToShow.filter((product) => { var _a; return ((_a = product === null || product === void 0 ? void 0 : product.producer) === null || _a === void 0 ? void 0 : _a.id) == activeCategory.id; });
        }
        else {
            productsToShow = productsToShow.filter((product) => product.category.some((category) => category.id == activeCategory.id));
        }
    }
    if (activeSubCategory) {
        productsToShow = productsToShow.filter((product) => product.category.some((category) => category.id == activeSubCategory.id));
    }
    return productsToShow;
}
export function isProductSoldOut(stock, product) {
    return stock.productIdToQuantityPossible[product.id] <= 0 || product.soldOut === '1';
}
export function isStoreEnabledProductSoldOut(product, deliveryDateString) {
    return product.availability.length == 1
        ? !product.availability.some((a) => a.quantity > SOLD_OUT_LIMIT)
        : !product.availability
            .filter((a) => a.date == deliveryDateString)
            .some((a) => a.quantity > SOLD_OUT_LIMIT);
}
export function getRelatedProducts(productIds, regionId) {
    const ids = productIds.map((id) => `id=${id}`).join('&');
    return axios.get(`${process.env.CORE_API}/recommendations/related-products/${regionId}?${ids}`);
}
export function getPopularProducts(regionId, deliveryDate, limit) {
    return axios.get(`${process.env.CORE_API}/recommendations/popular-products/${regionId}/${encodeURIComponent(deliveryDate)}?limit=${limit}`);
}
export function hasAvailability(object) {
    return object != undefined && 'availability' in object;
}
